import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useI18next } from 'gatsby-plugin-react-i18next';

function Seo({ description, meta, title, defTitle, img }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  )
  console.log(site)
  const { language } = useI18next();

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{ language }}
      title={title}
      titleTemplate={
        defTitle ? site.siteMetadata.title : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: img,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          name: `robots`,
          content: "index, follow",
        },
      ].concat(meta)}
    >
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BG98KSPT4X"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-BG98KSPT4X');`}
      </script>

      <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/d193e0b47275983fc5298ce9/script.js"></script>

    </Helmet>
  )
}

Seo.defaultProps = {
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
