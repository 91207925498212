import { ConsentGtag } from "./consentGtag"

export const handleCall = (url, type) => {
    if (url.includes('tel:+351234') || url.includes('tel:+351 234')) {
        type = 'Geral'
    }
    if (url.includes('tel:+351968732504') || url.includes('tel:+351 968 732 504')) {
        type = 'João Queirós'
    }
    if (url.includes('tel:+351963844810') || url.includes('tel:+351 963 844 810')) {
        type = 'Daniel Gomes'
    }
    if (url.includes('tel:+351926291052') || url.includes('tel:+351 926 291 052')) {
        type = 'Nelson Silva'
    }
    ConsentGtag()
    if (typeof window !== "undefined") {
        window.gtag('event', 'chamadas', {
            tipo_chamada: type
        })
        window.open(url, '_self', 'noreferrer')
    }
}